import FileItem from "./fileItem";

export default function FileList({ runData }) {
    return (
        <div>
            <label className="block text-base text-gray-700 font-semibold">{runData.valueId} {runData.objectValueCount > 10 && <span>({runData.objectValueCount} records)</span>}</label>
            <div className="flex flex-col space-y-3">
                {runData.files?.map((fileData, index) => (
                    <div className="border rounded-lg px-3 py-4 flex flex-col space-y-3" key={index}>
                        <div>
                            <span className="border-transparent rounded-lg bg-gray-100 px-2.5 py-1 font-semibold">{index + 1}</span>
                        </div>
                        <div className="px-6">
                            <FileItem fileData={fileData} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}