import { useNavigate } from "react-router-dom";
import DayRangePicker from "../../../../components/DayRangePicker";
import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "contexts";
import { Select } from "../../../../components/Form/Select";
import { workflowLogStatusNames } from "../../../../utils/constants";
import { useServerSideTableActions, useTableActions, useToastAction } from "@metaforcelabs/metaforce-core";
import { SidebarButton } from "../sideBarButton";
import SideBarRunLog from "./sideBarRunLog";
import { searchRunLogs } from "../../../../api/workflowLog";
import { useDebounceCallback } from "@react-hook/debounce";
import { RotateCcw } from "lucide-react";

export default function SideBarTracing({ onClose }) {
    const navigate = useNavigate();
    const { workFlow } = useContext(WorkflowContext);
    const today = new Date();
    const last30days = new Date(new Date().setDate(new Date().getDate() - 30))
    const [dayRange, setDayRange] = useState({
        from: last30days,
        to: today,
    });
    const [logStatus, setLogStatus] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [selectedRunLog, setSelectedRunLog] = useState();
    const initialSearchRequest = {
        workflowId: workFlow.id,
        skip: 0,
        take: 5,
    }

    const searchAction = useToastAction();

    const handleSearch = async (skip = initialSearchRequest.skip, take = initialSearchRequest.take) => {
        searchAction.execute(async () => {
            const searchRequest = {
                workflowId: workFlow.id,
                skip: skip,
                take: take,
            };
            if (dayRange?.from) {
                searchRequest.from = new Date(dayRange.from.getFullYear(), dayRange.from.getMonth(), dayRange.from.getDate(), 0, 0, 0);
            }
            if (dayRange?.to) {
                searchRequest.to = new Date(dayRange.to.getFullYear(), dayRange.to.getMonth(), dayRange.to.getDate(), 23, 59, 59);
            }
            if (logStatus !== '') {
                searchRequest.status = logStatus;
            }

            const result = await searchRunLogs(searchRequest);
            setSearchData(result);

            if (skip === 0)
                tableActions.reset();
        }, "Search failed")
    }

    const debounceSearch = useDebounceCallback(handleSearch, 1000);

    const tableActions = useServerSideTableActions(searchData.runLogs, initialSearchRequest.take, searchData.totalRecords, (skip, take) => debounceSearch(skip, take));

    useEffect(() => {
        debounceSearch();
    }, []);

    const handleClose = () => {
        onClose();
        navigate(`/admin/workflow/editor/${workFlow.id}`);
    }

    const handleRefreshClick = async () => {
        setLogStatus('');
        debounceSearch();
    }

    const handleRunLogClick = (v) => {
        setSelectedRunLog(v)
        navigate(`/admin/workflow/editor/${workFlow.id}/run/${v.workflowValuesId}` + (v.loopTracker ? `/${v.loopTracker.currentIteration}` : ""));
    }

    return (
        <div className="mt-10">
            <div className="flex justify-between">
                <h3 className="font-medium text-gray-900">History</h3>
                <button
                    type="button"
                    className="border-none text-sm font-medium text-indigo-500 hover:text-indigo-700"
                    onClick={handleClose}
                >
                    Exit history
                </button>
            </div>
            <div className="mt-3">
                <div className="absolute w-full pr-12">
                    <DayRangePicker
                        id="searchDayRange"
                        label={"Date"}
                        dayRange={dayRange}
                        onChange={(v) => {
                            setDayRange(v)
                            debounceSearch()
                        }}
                    />
                </div>
                <div className="h-16"></div>
                <div className="pt-2">
                    <Select
                        id="statuses"
                        name="statuses"
                        label="Status"
                        defaultOptionText="All statuses"
                        options={Object.keys(workflowLogStatusNames).map(x => ({ name: workflowLogStatusNames[x], value: x }))}
                        selectedValue={logStatus}
                        onChange={(v, name) => {
                            const status = Number(v);
                            setLogStatus(status);
                            debounceSearch();
                        }}
                    />
                </div>
                <div className="grid grid-cols-2 gap-x-3 mt-4">
                    <SidebarButton
                        text='Refresh'
                        icon={() => <RotateCcw className="h-4 w-4" />}
                        readOnly={false}
                        onClick={handleRefreshClick}
                    />
                </div>
            </div>

            <div className="mt-8 flex flex-col gap-y-3">
                {tableActions?.pageData?.map((runLog) => (
                    <SideBarRunLog
                        key={runLog.id}
                        runLog={runLog}
                        selectedRunLog={selectedRunLog}
                        onChange={handleRunLogClick}
                    />
                ))}
                {tableActions?.pageData?.length > 0 && (
                    <div className="flex flex-1 justify-between">
                        <button
                            disabled={!tableActions?.hasPreviousPage}
                            onClick={() => tableActions.previousPage()}
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                            Previous
                        </button>

                        <button
                            disabled={!tableActions?.hasNextPage}
                            onClick={() => tableActions.nextPage()}
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}