import { useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getWorkflowTableData, deleteWorkflowTableData } from '../../../api/workflowTables';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { WorkflowTableCtxMenu } from './Components/workflowTableCtxMenu';
import { workflowTableFieldType } from "../../../utils/constants";
import { PageHeader } from '@metaforcelabs/metaforce-core';

export default function WorkFlowTableData() {
    const [workflowTableData, setWorkflowTableData] = useState({
            name: '',
            description: '',
            fields: [],
            rowData: []
        });
    const { workflowTableId } = useParams();
    
    const loadAction = useToastAction();

    const showIdField = false;

    const loadData = async (workflowTableId) => {
        loadAction.execute(async () => {
            const tableData = await getWorkflowTableData(workflowTableId);
            setWorkflowTableData(tableData);
            
        }, "Failed to load workflow table");
    }

    const outputData = (row, field) => {
        switch (field.type) {
            case workflowTableFieldType.string:
                return <td className="px-6 py-3">{row?.columnData[field.id]?.Data}</td>
            case workflowTableFieldType.integer:
            case workflowTableFieldType.double:
                return <td className="px-6 py-3 text-right">{row?.columnData[field.id]?.Data.toString()}</td>
            case workflowTableFieldType.boolean:
                return <td className="px-6 py-3">
                        <input
                        type="checkbox"
                        id={row.id + '-' + field.id}
                        disabled={true}
                        checked={row?.columnData[field.id]?.Data}
                    />
                </td>
            case workflowTableFieldType.date:
                return <td className="px-6 py-3 whitespace-nowrap text-sm">{stringToLocaleDateTimeString(
                    row?.columnData[field.id]?.Data
                )}</td>
        }
        return <td className="px-6 py-3">Unknown field type</td>
    }
   
    const handleDestroyWorkflowTableRow = async (workflowTableRow, fieldIndex) => {
        const rowData = workflowTableData.rowData.slice(0);
        rowData.splice(fieldIndex, 1);
        setWorkflowTableData({
            ...workflowTableData,
            ...{
                rowData: rowData
            }
        });
        deleteWorkflowTableData(workflowTableId, workflowTableRow.id);
    }

    useEffect(() => {
        loadData(workflowTableId);
    }, [workflowTableId])


    return (
        <>
            <div className="h-full">
                <div className="max-w-screen-2xl h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
                    <PageHeader
                        title={workflowTableData?.name}
                    />
                    <div className="whitespace-pre-wrap mb-16">{workflowTableData?.description}</div>
                    <div className="flex h-full gap-6 mt-6">
                        <div className="flex-1 h-full">
                            <div className="relative z-0">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        {workflowTableData?.fields.map((f) => 
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-left text-xs font-medium whitespace-nowrap"
                                                            >
                                                                {f.name}
                                                            </th>
                                                        )}
                                                        {showIdField && (
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 whitespace-nowrap uppercase"
                                                            >
                                                                Id
                                                            </th>
                                                        )}
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 whitespace-nowrap uppercase"
                                                        >
                                                            Created
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 whitespace-nowrap uppercase"
                                                        >
                                                            Updated
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="relative px-6 py-3"
                                                        >
                                                            <span className="sr-only">Edit</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {workflowTableData?.rowData.map((row, i) => (
                                                        <tr>
                                                            {workflowTableData?.fields.map((f) => outputData(row, f))}
                                                            {showIdField && (
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                    {row.id}
                                                                </td>
                                                            )}
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                {stringToLocaleDateTimeString(
                                                                    row.updatedDate
                                                                )}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                {stringToLocaleDateTimeString(
                                                                    row.updatedDate
                                                                )}
                                                            </td>
                                                            <td className="p-2 whitespace-nowrap text-sm font-medium">
                                                                <WorkflowTableCtxMenu workflowTableRow={row} fieldIndex={i} 
                                                                    onDestroy={(wf, i) => handleDestroyWorkflowTableRow(wf, i)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
