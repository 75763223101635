import { useEffect, useState } from "react"
import WorkflowEventAppIcon from "../../workflowAppIcon"

export default function FilterConditionRules({ filterGroups, inputDatas }) {
    return (
        <div>
            <div className="pb-4 text-base text-gray-700 font-semibold">Continue if</div>
            {
                filterGroups?.map((fg, gIdx) => {
                    return (
                        <div key={"gr-" + gIdx}>
                            {
                                gIdx > 0 && (
                                    <div className="mt-4 pt-4 pb-3 border-t border-solid border-gray-400 text-base text-gray-700 font-semibold">
                                        Or if
                                    </div>
                                )
                            }
                            <div className="flex flex-col space-y-3">
                                {
                                    fg.groupFilters && fg.groupFilters.map((gf, gfIdx) => {
                                        return (
                                            <div key={"fi-" + gIdx + gfIdx} className="">
                                                <div className="">
                                                    {
                                                        gfIdx > 0 && (
                                                            <div className="mt-2 mb-1 text-base text-gray-700 font-semibold">
                                                                And
                                                            </div>
                                                        )
                                                    }
                                                    <FilterCondtion filterCondition={gf} inputDatas={inputDatas} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const FilterFieldValue = ({ buildCondition }) => {
    if (!buildCondition?.field)
        return <span>&nbsp;</span>

    return (
        <span className="space-x-2 px-2  bg-white rounded border-gray-200 border ">
            <WorkflowEventAppIcon iconKey={buildCondition?.field?.icon} className="h-4 w-5 inline-block shadow rounded p-0.5" />
            <span className="font-semibold text-md">{buildCondition?.field?.stepIdx + 1}. {buildCondition?.field?.name} Value: </span>
            <span className="text-md text-gray-500">{buildCondition?.fieldValue}</span>
        </span>
    )
}

const FilterCondtion = ({ filterCondition, inputDatas }) => {
    const [buildCondition, setBuildCondition] = useState(filterCondition || {})

    useEffect(() => {
        const fieldInputData = inputDatas?.find(x => x.valueId === filterCondition.field?.id && x.workflowStepId === filterCondition.field?.stepId);
        let filterConditionValue = filterCondition?.value;
        switch (filterCondition.condition?.key) {
            case "bool-is-true":
                filterConditionValue = "True";
                break;
            case "bool-is-false":
                filterConditionValue = "False";
                break;
            default:
                filterConditionValue = filterCondition?.value;
                break;
        }
        setBuildCondition({
            ...filterCondition,
            value: filterConditionValue,
            fieldValue: fieldInputData ? fieldInputData.value : ''
        })
    }, [filterCondition, inputDatas])

    return (
        <div className="grid grid-cols-6 space-x-2">
            <div className="rounded col-span-2 bg-gray-100 px-2 py-2 text-base text-gray-700 font-semibold break-all">
                <FilterFieldValue buildCondition={buildCondition} />
            </div>
            <div className="rounded col-span-2 bg-gray-100 px-2 py-2 text-base text-gray-700 font-semibold break-all">
                {buildCondition.condition?.name}
            </div>
            <div className="rounded col-span-2 bg-gray-100 px-2 py-2 text-base text-gray-700 font-semibold break-all">
                {buildCondition.value}
            </div>
        </div>
    )
}