import { useToastAction, ButtonV2, CoreButtonTypes } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getWorkflowTableDefinition, updateWorkflowTableDefinition } from '../../../api/workflowTables';
import { WorkflowTableCtxMenu } from './Components/workflowTableCtxMenu';
import { workflowTableFieldType } from "../../../utils/constants";
import { PageHeader } from '@metaforcelabs/metaforce-core';
import Modal from '../../../components/Modals/modal';
import Select from "react-select";
import toast from "react-hot-toast";
import * as yup from "yup";

export default function WorkFlowTableStructure() {
    const defaultField = { 
        name: "",
        type: 0, 
        position: -1
    };
    let propertySchema = yup.object({
        name: yup.string().required("Required"),
        type: yup.number().required("Required"),
        position: yup.number().required("Required"),
    });
    const [workflowTable, setWorkflowTable] = useState({
        id: '',
        name: '',
        description: '',
        fields: []
    });
    const [workflowTableFieldTypeOptions, setWorkflowTableFieldTypeOptions] = useState([]);
    const [workflowTablePositionOptions, setWorkflowTablePositionOptions] = useState([]);
    const [showNewFieldModal, setShowNewFieldModal] = useState(false);
    const [newField, setNewField] = useState(defaultField);
    const [formStateValid, setFormStateValid] = useState(false);
    const { workflowTableId } = useParams();
    
    const loadAction = useToastAction();

    const loadData = async (workflowTableId) => {
        loadAction.execute(async () => {
            const table = await getWorkflowTableDefinition(workflowTableId);
            setWorkflowTable(table);
            setWorkflowTableFieldTypeOptions(Object.keys(workflowTableFieldType).map((f) => ({
                label: f,
                value: workflowTableFieldType[f]
            })));
            updatePositionOptions(table.fields);
        }, "Failed to load workflow table");
    }

    useEffect(() => {
        loadData(workflowTableId);
    }, [workflowTableId])

    useEffect(() => {
        try {
            propertySchema.validateSync(newField);
            setFormStateValid(true)
        }
        catch (error) {
            setFormStateValid(false)
        }
      }, [newField])

    const handleDestroyWorkflowTableField = async (workflowTable, fieldIndex) => {
        const fields = workflowTable.fields.slice(0);
        fields.splice(fieldIndex, 1);
        updateFields(fields);
        updatePositionOptions(fields);
    }

    const createField = (form) => {
        const ind = newField.position >= 0 ? newField.position : workflowTable.fields.length;

		if (newField.name == '') {
            throw new Error('Name is not given');
        }
        else if(workflowTable.fields.find((f) => f.name === newField.name) != undefined) {
            throw new Error('Field name already exists');
        }
        else if (isNaN(newField.type) || newField.type < 0 || newField.type > workflowTableFieldTypeOptions.length) {
            throw new Error('Invalid type');
        }
        else if (isNaN(ind) || ind < 0 || ind > workflowTable.fields.length) {
            throw new Error('Invalid position');
        }

        else {
            const fields = [
                ...workflowTable.fields.slice(0, ind),
                {
                    name: newField.name,
                    type: newField.type,
                },
                ...workflowTable.fields.slice(ind)
            ];
            updateFields(fields);
            updatePositionOptions(fields);
            setNewField(defaultField);
        }
    }

    const updateFields = (fields) => {
        const table = {
            ...workflowTable,
            fields: fields
        };
        setWorkflowTable(table);
        updateWorkflowTableDefinition(table.id, table.name, table.description, table.fields);
    }

    const updatePositionOptions = (fields) => {
        const positionOptions = [
            {
                label: 'At the end of the table',
                value: -1
            },
            {
                label: 'At the beginning of the table',
                value: 0
            },
            ...fields.map((f, i) => ({
                label: 'After "' + f.name + '"',
                value: i + 1
            }))
        ];
        setWorkflowTablePositionOptions(positionOptions);
    }

    return (
        <>
            <div className="h-full">
                <div className="max-w-screen-2xl h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
                    <PageHeader
                        title={workflowTable?.name}
                    />
                    <div className="whitespace-pre-wrap mb-16">{workflowTable?.description}</div>
                    <div className="text-right">
                        <ButtonV2
                            label={"New table field"}
                            type={CoreButtonTypes.cta}
                            onClick={() => setShowNewFieldModal(true)}
                        />
                    </div>
                    <div className="flex h-full gap-6 mt-6">
                        <div className="flex-1 h-full">
                            <div className="relative z-0">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                        >
                                                            Field name
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                        >
                                                            Field type
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                        >
                                                            Field Id
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="relative px-6 py-3"
                                                        >
                                                            <span className="sr-only">Edit</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {workflowTable?.fields.map((f, i) => (
                                                        <tr>
                                                            <td className="px-6 py-3">{f.name}</td>
                                                            <td className="px-6 py-3">{workflowTableFieldTypeOptions.find((t) => t.value === f.type)?.label}</td>
                                                            <td className="px-6 py-3">{f.id}</td>
                                                            <td className="p-2 whitespace-nowrap text-sm font-medium">
                                                                <WorkflowTableCtxMenu workflowTable={workflowTable} fieldIndex={i} 
                                                                    onDestroy={(wf, i) => handleDestroyWorkflowTableField(wf, i)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={showNewFieldModal}
                onClose={() => {
                    setShowNewFieldModal(false)
                }}
                size="medium"
                showOverflow="true"
            >
                <form>
                    <div className="mt-4">
                        <input
                            type="text"
                            name="field-name"
                            id="field-name"
                            className="focus:ring-brand-pink focus:border-brand-pink w-full rounded-md sm:block sm:text-sm border-gray-300"
                            placeholder="Field name"
                            value={newField.name}
                            onChange={e => {
                                setNewField({ ...newField, name: e.target.value })
                            }}
                        />
                    </div>
                    <div className="mt-4">
                        <Select
                            name="field-type"
                            id="field-type"
                            className="focus:ring-brand-pink focus:border-brand-pink w-full rounded-md sm:block sm:text-sm border-gray-300 capitalize"
                            placeholder="Field type"
                            isSearchable={false}
                            options={workflowTableFieldTypeOptions}
                            defaultValue={workflowTableFieldTypeOptions[0]}
                            onChange={e => {
                                setNewField({ ...newField, type: Number(e.value) })
                            }}
                        />
                    </div>
                    <div className="mt-4">
                        <Select
                            name="field-position"
                            id="field-position"
                            className="focus:ring-brand-pink focus:border-brand-pink w-full rounded-md sm:block sm:text-sm border-gray-300"
                            placeholder="Position"
                            isSearchable={false}
                            options={workflowTablePositionOptions}
                            defaultValue={workflowTablePositionOptions[0]}
                            onChange={e => {
                               setNewField({ ...newField, position: Number(e.value) })
                            }}
                        />
                    </div>
                    <div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                        <button
                            id="submitButton"
                            type="button"
                            className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-base font-medium text-white sm:text-sm"
                            // disabled={!formStateValid || saveAction.isExecuting}
                            onClick={(e) => {
                                try {
                                    createField(e.target.parentNode.parentNode);
                                    setShowNewFieldModal(false);
                                }
                                catch(err) {
                                    toast.error(err.message);
                                }
                            }}
                            disabled={!formStateValid}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
                            onClick={(event) => {
                                event.preventDefault();
                                setShowNewFieldModal(false)
                                // setNewProperty(defaultProperty);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}
