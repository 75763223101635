import FileItem from "./fileItem";

export default function ArrayItem({ runData }) {
    return (
        <div>
            <label className="block text-base text-gray-700 font-semibold">{runData.valueId} {runData.objectValueCount > 10 && <span>({runData.objectValueCount} records)</span>}</label>
            <div className="flex flex-col space-y-2" >
                {
                    runData.objectValue && (
                        <>
                            {Object.keys(runData.objectValue).sort().map((idx) => (
                                <>
                                    {runData.objectValue[idx]?.files?.length > 0
                                        ?
                                        <div className="border-transparent rounded-lg bg-gray-100 px-3 py-3">
                                            {
                                                runData.objectValue[idx].files.map((fileData, fileIndex) => (
                                                    <div className="border rounded-lg px-3 py-4 flex flex-col space-y-3 bg-white" key={fileIndex}>
                                                        <div>
                                                            <span className="border-transparent rounded-lg bg-gray-100 px-2.5 py-1 font-semibold">{fileIndex + 1}</span>
                                                        </div>
                                                        <div className="px-6">
                                                            <FileItem fileData={fileData} />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        :
                                        <div key={idx} className="w-full border-transparent rounded-lg bg-gray-100 px-4 py-2 text-base break-all" >
                                            {!runData.objectValue[idx]?.value || runData.objectValue[idx]?.value.trim() === ' ' ? '\ufeff' : runData.objectValue[idx]?.value}
                                        </div>
                                    }
                                </>
                            ))}
                        </>
                    )
                }
            </div>
        </div>
    )
}