import { AuthenticationProvider, getUserManager, InMemoryWebStorage, oidcLog, OidcSecure, useReactOidc } from '@axa-fr/react-oidc-context';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { oidcConfig } from './utils/config';
import { NotFound } from './screens/Errors/NotFound';
import MissingLicense from './screens/Errors/MissingLicense';
import { Unauthorized } from './screens/Errors/Unauthorized';
import Dashboard from './screens/admin/Dashboard';
import { Toaster } from './toaster';
import AdminLayout from './layouts/AdminLayout';
import { getUserProfile } from './api/userProfile';
import { getCustomerInfo, getLicensedProducts } from './api/customer';
import { OidcRoutesContext } from './contexts';
import { setAuthHeader } from './api/api';
import Oidc from './screens/Oidc';
import WorkFlowList from './screens/admin/WorkFlowList';
import WorkFlowTableStructure from './screens/admin/WorkFlowTableStructure';
import WorkFlowTableData from './screens/admin/WorkFlowTableData';
import WorkFlowTables from './screens/admin/WorkFlowTables';
import WorkFlowEditor from './screens/admin/WorkFlowEditor';
import "jsextensions";
import TriggerReceiver from './screens/Workflow/triggerReceiver';
import Worklfow from './screens/Workflow';
import { WorkflowData } from './screens/admin/WorkflowData';
import { AlertModal, useToastAction } from '@metaforcelabs/metaforce-core';
import WorkFlowApps from './screens/admin/WorkFlowApps';
import WorkflowOAuthAppConnections from './screens/admin/WorkFlowApps/WorkflowOAuthAppConnections';
import { routePath } from './utils/constants';
import WorkflowVersions from './screens/admin/WorkflowVersions';

function App() {
  const isPublicRoute = window.location.pathname.startsWith("/public");

  return (
    <div className="app h-screen">

      <RecoilRoot>
        <BrowserRouter>
          {isPublicRoute ? (
            <Routes>
              <Route path="/public" >
                <Route path="workflow/:workflowId/trigger/:refererId" element={
                  <TriggerReceiver />
                } />
                <Route path="workflow/:workflowId/:workflowStepId/instance/:workflowValuesId/:refererId" element={
                  <Worklfow />
                } />
                <Route path="workflow/:workflowId/:workflowStepId/instance/:workflowValuesId" element={
                  <Worklfow />
                } />
              </Route>
            </Routes>
          ) : (
            <AuthenticationProvider
              configuration={oidcConfig}
              loggerLevel={oidcLog.ERROR}
              isEnabled={true}
              UserStore={InMemoryWebStorage}
              callbackComponentOverride={Oidc}
              notAuthenticated={() => { return (<h1>Not Authenticated.</h1>) }}
              notAuthorized={() => { return (<h1>Not Authorized.</h1>) }}
              authenticating={Oidc}
            >
              <OidcSecure>
                <SetAuthToken>
                  <SetOidcRoutesContext>
                    <AlertModal>
                      <Routes>
                        <Route path="/unauthorized" element={<Unauthorized />}>
                        </Route>
                        <Route path="/missing-license" element={<MissingLicense />} />
                        <Route path="/not-found" element={<NotFound />} />
                        <Route path="/otherpath" element={
                          <AdminLayoutRoute nav="otherpath" subMenuNav="otherpath" component={() => { return (<h1>Pther path</h1>) }} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow-list" element={
                          <AdminLayoutRoute nav="admin/workflow_list" subMenuNav="admin/workflow_list" component={WorkFlowList} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow-table/structure/:workflowTableId" element={
                          <AdminLayoutRoute nav="admin/workflow_table/structure" subMenuNav="admin/workflow_table/structure" component={WorkFlowTableStructure} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow-table/data/:workflowTableId" element={
                          <AdminLayoutRoute nav="admin/workflow_table/data" subMenuNav="admin/workflow_table/data" component={WorkFlowTableData} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow-tables" element={
                          <AdminLayoutRoute nav="admin/workflow_tables" subMenuNav="admin/workflow_tables" component={WorkFlowTables} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow/" element={
                          <AdminLayoutRoute nav="admin/workflow" subMenuNav="admin/workflow" component={Dashboard} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow/data/:workflowId" element={
                          <AdminLayoutRoute nav="admin/workflow/data" subMenuNav="admin/workflow" component={WorkflowData} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow/editor/:workflowId" element={
                          <AdminLayoutRoute nav="admin/workflow/editor" subMenuNav="admin/workflow" component={WorkFlowEditor} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow/editor/:workflowId/run/:workflowValuesId" element={
                          <AdminLayoutRoute nav="admin/workflow/editor" subMenuNav="admin/workflow" component={WorkFlowEditor} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow/editor/:workflowId/run/:workflowValuesId/:loopIteration" element={
                          <AdminLayoutRoute nav="admin/workflow/editor" subMenuNav="admin/workflow" component={WorkFlowEditor} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow/versions/:workflowId" element={
                          <AdminLayoutRoute nav="admin/workflow/versions" subMenuNav="admin/workflow" component={WorkflowVersions} featureEnabled={true} />
                        } />
                        <Route path="admin/dashboard" element={
                          <AdminLayoutRoute nav="admin/dashboard" subMenuNav="dashboard" component={Dashboard} featureEnabled={true} />
                        } />
                        <Route path="/" element={
                          <AdminLayoutRoute nav="dashboard" subMenuNav="dashboard" component={Dashboard} featureEnabled={true} />
                        } />
                        <Route path="/admin/workflow-apps" element={
                          <AdminLayoutRoute nav="admin/workflow_apps" subMenuNav="admin/workflow_apps" component={WorkFlowApps} featureEnabled={true} />
                        } />
                        <Route path={routePath.microsoftOutlook} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/microsoft-outlook" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={routePath.microsoftOutlookWorkflows} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/microsoft-outlook/workflows" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={`${routePath.microsoftOutlookWorkflows}/:connectionId`} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/microsoft-outlook/workflows" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={routePath.kivra} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/kivra" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={routePath.kivraWorkflows} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/kivra/workflows" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={`${routePath.kivraWorkflows}/:connectionId`} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/kivra/workflows" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={routePath.digipost} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/digipost" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={routePath.digipostWorkflows} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/digipost/workflows" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={`${routePath.digipostWorkflows}/:connectionId`} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/digipost/workflows" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={routePath.sftp} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/sftp" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={routePath.sftpWorkflows} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/sftp/workflows" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={`${routePath.sftpWorkflows}/:connectionId`} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/sftp/workflows" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={routePath.openAI} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/openai" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={routePath.openAIWorkflows} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/openai/workflows" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                        <Route path={`${routePath.openAIWorkflows}/:connectionId`} element={
                          <AdminLayoutRoute nav="admin/workflow_apps/openai/workflows" subMenuNav="admin/workflow_apps" component={WorkflowOAuthAppConnections} featureEnabled={true} />
                        } />
                      </Routes>
                    </AlertModal>
                    <Toaster />
                  </SetOidcRoutesContext>
                </SetAuthToken>
              </OidcSecure>
            </AuthenticationProvider>
          )
          }
        </BrowserRouter >
      </RecoilRoot >
    </div >
  );
}

function AdminLayoutRoute({
  component: Component,
  featureEnabled,
  nav,
  subMenuNav,
  ...rest
}) {
  return (
    <FeatureFlagRoute featureEnabled={featureEnabled}>
      <AdminLayout nav={nav} subMenuNav={subMenuNav} {...rest}>
        <Component {...rest} />
      </AdminLayout>
    </FeatureFlagRoute>
  )
}

const FeatureFlagRoute = ({ featureEnabled, children }) => {
  return featureEnabled ? (
    <>
      {children}
    </>
  ) :
    (
      // React router dom v6 change. 
      // Navigate used to be named Redirect
      <Navigate to={'/'} />
    )
}

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});

  const loadUserContextData = async () => {
    loadAction.execute(async () => {
      const userProfilePromise = getUserProfile();
      const customerPromise = getCustomerInfo();
      const licensedProductsPromise = getLicensedProducts();
      const res = await Promise.all([userProfilePromise, customerPromise, licensedProductsPromise]);
      setUserProfile(res[0]);
      const customerInfoData = res[1];
      const licencedProductCodes = res[2].map(x => x.productCode)
      setCustomerInfo({ ...customerInfoData, licencedProductCodes });
    }, "Failed to load user data");
  }

  useEffect(() => {
    loadUserContextData();
  }, []);

  return !loadAction.isExecuting && (
    <OidcRoutesContext.Provider
      value={{
        userProfile,
        customerInfo,
      }}>
      {children}
    </OidcRoutesContext.Provider>
  )
}

/**
 * Small route wrapper that gets the oidc user and sets the axios auth header 
 */
const SetAuthToken = ({ children }) => {
  const { oidcUser, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token)

  useEffect(() => {
    if (!events) return
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events])

  const addUserSignedOut = () => {
    const userManager = getUserManager()
    userManager.removeUser();
    window.location.reload(false);
  }

  return (
    <>
      {children}
    </>
  );
}

export default App;
