import { get, post, BaseUrl, buildStatusCodeHandler } from './api';

const controller = "WorkflowLog";

export const searchRunLogs = async (request) => {
    return await post(`${controller}/search`, request, null, BaseUrl.WorkFlow);
}

export const getRunLog = async (workflowId, workflowValuesId, loopIteration = 0, notFoundHandler) => {
    return await get(`${controller}/${workflowId}/${workflowValuesId}/${loopIteration}`, buildStatusCodeHandler(404, notFoundHandler), BaseUrl.WorkFlow);
}

export const getDataOut = async (stepLogId, notFoundHandler) => {
    return await get(`${controller}/dataout/${stepLogId}`, buildStatusCodeHandler(404, notFoundHandler), BaseUrl.WorkFlow);
}

export const getDataIn = async (stepLogId, notFoundHandler) => {
    return await get(`${controller}/datain/${stepLogId}`, buildStatusCodeHandler(404, notFoundHandler), BaseUrl.WorkFlow);
}