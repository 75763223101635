import { SearchIcon } from '@heroicons/react/outline'
import { ButtonV2, CoreButtonTypes, Table } from '@metaforcelabs/metaforce-core';
import { useTableSearch, useTableActions, useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { destroyWorkflowTable, getWorkflowTables } from '../../../api/workflowTables';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { WorkflowTablesCtxMenu } from './Components/workflowTablesCtxMenu';
import NewWorkflowTable from '../newWorkflowTable';
import { PageHeader } from '@metaforcelabs/metaforce-core';

export default function WorkFlowTables() {
    const [showNewTableDialog, setShowNewTableDialog] = useState(false);
    const [workflowTables, setWorkflowTables] = useState([]);
    const tableActions = useTableActions(workflowTables, 10, "createdDate", "desc");
    const [searchTerm, setSearchTerm] = useState();

    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const tables = await getWorkflowTables();
            setWorkflowTables(tables);
        }, "Failed to load workflow tables");
    }

    useEffect(() => {
        loadData();
    }, [])

    /*
    const handleWorkflowTableChanged = (workflowTable) => {
        setWorkflowTables(prev => {
            const wfIdx = prev.findIndex(wf => wf.id === workflowTable.id);

            if (wfIdx !== -1) {
                prev[wfIdx] = workflowTable;
            }

            return [...prev];
        })
    }
    */

    const addWorkflowTable = (workflowTable) => {
        setWorkflowTables([
            ...workflowTables,
            workflowTable
        ]);
    }

    const handleWorkflowTableDestroyed = (workflowTable) => {
        setWorkflowTables(prev => {
            const filtered = prev.filter(x => x.id !== workflowTable.id);
            return [...filtered];
        })
    }

    const handleSearch = ({ value: searchBy }) => {
        setSearchTerm(searchBy);
        applySearchTerm(searchBy);
    }

    const applySearchTerm = (searchBy) => {
        tableActions.search(searchBy, "name");
    }

    return (
        <div className="h-full">
            <div className="max-w-screen-2xl h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
                <div className="flex flex-row justify-between items-end">
                    <PageHeader
                        title="Workflow Tables"
                        optionalSideElement={
                            <div className="flex gap-x-3">
                                <div className="mt-3 sm:mt-0 sm:ml-4 w-96">
                                    <label htmlFor="desktop-search-candidate" className="sr-only">
                                        Search
                                    </label>
                                    <div className="flex rounded-md shadow-sm">
                                        <div className="relative flex-grow focus-within:z-10">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <SearchIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <input
                                                type="text"
                                                name="desktop-search-candidate"
                                                id="desktop-search-candidate"
                                                className="hidden focus:ring-brand-pink focus:border-brand-pink w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                                                placeholder="Search"
                                                onChange={e => handleSearch(e.target)}
                                                value={searchTerm}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <ButtonV2
                                label={"Create new workflow table"}
                                type={CoreButtonTypes.cta}
                                onClick={() => setShowNewTableDialog(true)}
                                />
                            </div>
                        }
                    />
                </div>
                <div className="flex h-full gap-6 mt-6">
                    <div className="flex-1 h-full">
                        <div className="relative z-0">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                        onClick={() => tableActions.orderBy("name")}
                                                    >
                                                        Name {tableActions.getSortIcon("name")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                    >
                                                        Descriptions
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                        onClick={() =>
                                                            tableActions.orderBy("createdDate")
                                                        }
                                                    >
                                                        Created{" "}
                                                        {tableActions.getSortIcon("createdDate")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                                        onClick={() =>
                                                            tableActions.orderBy("updatedDate")
                                                        }
                                                    >
                                                        Updated{" "}
                                                        {tableActions.getSortIcon("updatedDate")}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="relative px-6 py-3"
                                                    >
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {tableActions?.pageData?.map((element) => (
                                                    <WorkflowTableTableRow key={element.id}
                                                        workflowTable={element}
                                                        /* onWorkflowTableChanged={wf => handleWorkflowTableChanged(wf)} */
                                                        onWorkflowTableDestroyed={wf => handleWorkflowTableDestroyed(wf)}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>
                                        <Table.Pagination tableActions={tableActions} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NewWorkflowTable open={showNewTableDialog} setOpen={setShowNewTableDialog} addTable={addWorkflowTable} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const WorkflowTableTableRow = ({ workflowTable, onWorkflowTableChanged, onWorkflowTableDestroyed }) => {
    const destroyAction = useToastAction();

    const handleDestroyWorkflowTable = async (workflowDef) => {
        destroyAction.execute(async () => {
            await destroyWorkflowTable(workflowDef.id);
            onWorkflowTableDestroyed(workflowDef);
        }, "Delete failed")
    }

    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-indigo-600 relative" style={{ minWidth: "250px" }}>
                <NavLink className={"cursor-pointer hover:underline"}
                    to={`/admin/workflow-table/structure/${workflowTable.id}`}
                >
                    {workflowTable.name}
                </NavLink>
            </td>
            <td className="px-6 py-4 text-sm text-gray-500" style={{ minWidth: "250px" }}>
                {workflowTable.description}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {stringToLocaleDateTimeString(
                    workflowTable.createdDate
                )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {stringToLocaleDateTimeString(
                    workflowTable.updatedDate
                )}
            </td>
            <td className="p-2 whitespace-nowrap text-sm font-medium">
                <WorkflowTablesCtxMenu workflowTable={workflowTable}
                    onDestroy={wf => handleDestroyWorkflowTable(wf)}
                />
            </td>
        </tr>
    )
}
